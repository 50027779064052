import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl:
      process.env.REACT_APP_UI_MODE === "development"
        ? "http://localhost:8080/api/v1/"
        : "https://gps-x.com/api/v1/",
    prepareHeaders: (headers, { _getState }) => {
      return headers;
    },
  }),
  tagTypes: [],
  endpoints: (builder) => ({
    uploadFile: builder.mutation({
      query: (data) => ({
        url: `tracks/convert`,
        method: "POST",
        body: data,
        formData: true,
        responseHandler: (response) => response.text(),
      }),
    }),
    convertUrl: builder.query({
      query: (url) => ({
        url: `api/v1/tracks/convert?file=${url}`,
        method: "GET",
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
});

export const { useUploadFileMutation, useConvertUrlQuery } = api;
