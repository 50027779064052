import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "../shared/ScrollToTop";
import Converter from "../features/converter";
import Viewer from "../features/viewer";
import { useLoadScript } from "@react-google-maps/api";

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

export default function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [mode, setMode] = React.useState(prefersDarkMode ? "dark" : "light");

  useLoadScript({
    googleMapsApiKey: "AIzaSyAXuseQWty6-rSXq3D_qgGnQJ_UTrD-kDc",
  });

  useEffect(() => {
    setMode(prefersDarkMode ? "dark" : "light");
    // eslint-disable-next-line
  }, [prefersDarkMode]);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: (newValue) => {
        setMode(() => {
          if (newValue === "auto") {
            return prefersDarkMode ? "light" : "dark"; // reversed?
          } else {
            return newValue;
          }
        });
      },
    }),
    // eslint-disable-next-line
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode,
        },
      }),
    [mode]
  );

  return (
    <BrowserRouter>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Container component="main" sx={{ mt: 8, mb: 8 }}>
              <ScrollToTop>
                <Routes>
                  <Route path="/" element={<Viewer />} />
                  <Route path="/csb2gpx" element={<Converter />} />
                  <Route
                    path="*"
                    element={
                      <main style={{ padding: "1rem" }}>
                        <p>There's nothing here!</p>
                      </main>
                    }
                  />
                </Routes>
              </ScrollToTop>
            </Container>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </BrowserRouter>
  );
}
