import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  file: undefined,
};

/* General app store slice for holding non-API fetched or perhaps local store persistence */
export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setFile: (state, action) => {
      state.file = action.payload;
    },
  },
});

export const { setFile } = appSlice.actions;

export default appSlice.reducer;
