import * as React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { darken, lighten, styled } from "@mui/material/styles";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";

const getBackgroundColor = (color, mode) =>
  mode === "dark" ? darken(color, 0.7) : lighten(color, 0.7);

export default function TrackTable(props) {
  const {
    data,
    scrollIndex,
    metric,
    selectedPoints,
    setSelectedPoints,
    deletePoints,
  } = props;
  const apiRef = useGridApiRef();

  React.useEffect(() => {
    setTimeout(() => {
      // no idea why this only works within a timeout
      apiRef.current?.scrollToIndexes({ rowIndex: scrollIndex });
      // apiRef.current.scroll({
      //   top: apiRef.current.getScrollPosition().top + 67,
      // });
    }, 0);
  }, [scrollIndex, apiRef]);

  const deleteFromStart = (selected) => {
    var toDelete = [selected[0]];
    data.every((d) => {
      if (d.id === selected[0]) {
        return false;
      } else {
        toDelete.push(d.id);
        return true;
      }
    });
    deletePoints(toDelete);
  };

  const deleteToEnd = (selected) => {
    var toDelete = [selected[0]];
    for (let index = data.length - 1; index > 0; index--) {
      const d = data[index];
      if (d.id !== selected[0]) {
        toDelete.push(d.id);
      } else {
        break;
      }
    }
    deletePoints(toDelete);
  };

  const CustomFooterStatusComponent = () => {
    if (selectedPoints.length > 0) {
      return (
        <Stack direction="row" spacing={2} sx={{ m: 1 }} alignItems="baseline">
          <Typography sx={{ mt: 3 }}>
            Selected points: {selectedPoints.length}
          </Typography>
          <Button onClick={() => setSelectedPoints([])}>Clear</Button>
          <Button onClick={() => deletePoints(selectedPoints)}>Delete</Button>
          {selectedPoints.length === 1 && (
            <Button onClick={() => deleteFromStart(selectedPoints)}>
              Delete To Start
            </Button>
          )}
          {selectedPoints.length === 1 && (
            <Button onClick={() => deleteToEnd(selectedPoints)}>
              Delete To End
            </Button>
          )}
        </Stack>
      );
    } else {
      return "";
    }
  };

  const columns = [
    {
      flex: 0.6,
      minWidth: 100,
      headerName: "Time",
      field: "time",
      valueGetter: (params) =>
        `${new Date(params.row.time).toLocaleDateString()} ${new Date(
          params.row.time
        ).toLocaleTimeString()}`,
    },
    {
      flex: 0.3,
      headerName: "Latitude",
      field: "lat",
    },
    {
      flex: 0.3,
      headerName: "Longitude",
      field: "lon",
    },
    {
      minWidth: 140,
      headerName: `Depth (${metric ? "m" : "ft"})`,
      field: "depth",
      type: "number",
      valueGetter: (params) => Math.abs(params.row.depth).toFixed(2),
    },
    {
      minWidth: 160,
      headerName: `Distance (${metric ? "m" : "ft"})`,
      field: "dist",
      type: "number",
    },
  ];

  return (
    <Paper style={{ height: 250, width: "100%" }}>
      <DataGridPro
        apiRef={apiRef}
        rows={data}
        columns={columns}
        checkboxSelection
        rowSelectionModel={selectedPoints}
        getRowClassName={(params) => {
          if (scrollIndex === params.id) {
            return "Mui-selected";
          }
          return "";
        }}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setSelectedPoints(newRowSelectionModel);
        }}
        slots={{
          footer: CustomFooterStatusComponent,
        }}
      />
    </Paper>
  );
}
