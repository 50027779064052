import React from "react";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import TrackTable from "./TrackTable";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import ZoomableLineChart from "./ZoomableLineChart";
import TableChartIcon from "@mui/icons-material/TableChart";
import Tooltip from "@mui/material/Tooltip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MapView from "./MapView";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import InputAdornment from "@mui/material/InputAdornment";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { useSelector } from "react-redux";
import { intervalToDuration } from "date-fns";

export default function Viewer() {
  const file = useSelector((state) => state.app.file);
  const [trackSegment, setTrackSegment] = React.useState("");
  const [trackSegments, setTrackSegments] = React.useState([]);
  const [segmentMap, setSegmentMap] = React.useState(undefined);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [gpxFile, setGpxFile] = React.useState(undefined);
  const [scrollIndex, setScrollIndex] = React.useState(0);
  const [metric, setMetric] = React.useState(false);
  const [xmlDoc, setXmlDoc] = React.useState(undefined);
  const [dms, setDms] = React.useState(false);
  const [selectedPoints, setSelectedPoints] = React.useState([]);
  const [offset, setOffset] = React.useState("0.0");
  const [pointThreshold, setPointThreshold] = React.useState(0);
  const [dotSize, setDotSize] = React.useState(2);
  const [fileName, setFileName] = React.useState(undefined);
  const [saveFile, setSaveFile] = React.useState(false);
  const [duration, setDuration] = React.useState("");
  const [trackPointer, setTrackPointer] = React.useState(false);

  React.useEffect(() => {
    if (file) {
      var xmlDoc = new DOMParser().parseFromString(file, "text/xml");
      setXmlDoc(xmlDoc);
    }
    // eslint-disable-next-line
  }, [file]);

  React.useEffect(() => {
    if (gpxFile) {
      handleGPXFile();
    }
    // eslint-disable-next-line
  }, [gpxFile]);

  const deletePoints = (points) => {
    let data = selected.data.filter((d) => points.includes(d.id) === false);
    // recalculate distance, max, min
    let min = 0;
    let max = 0;
    if (data.length > 0) {
      data[0].dist = 0;
      data.forEach((d, index) => {
        let depth = d.depth;
        min = Math.min(min, depth);
        max = Math.max(max, depth);
        if (index > 0) {
          data[index].dist = calcDistance(
            { latitude: data[index].latDD, longitude: data[index].lonDD },
            {
              latitude: data[index - 1].latDD,
              longitude: data[index - 1].lonDD,
            }
          );
        }
      });
    }
    setSelected({ ...selected, ...{ data: data, max: max, min: min } });
    setSelectedPoints([]);
  };

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const convertToFeet = (meters) => {
    return meters * 3.28084;
  };

  const convertToMeters = (feet) => {
    return feet * 0.3048;
  };

  const convertDmsLatitude = (lat) => {
    var latitude = toDegreesMinutesAndSeconds(lat);
    var latitudeCardinal = lat >= 0 ? "N" : "S";

    return latitude + " " + latitudeCardinal;
  };

  const convertDmsLongitude = (lng) => {
    var longitude = toDegreesMinutesAndSeconds(lng);
    var longitudeCardinal = lng >= 0 ? "E" : "W";

    return longitude + " " + longitudeCardinal;
  };

  const toDegreesMinutesAndSeconds = (coordinate) => {
    var absolute = Math.abs(coordinate);
    var degrees = Math.floor(absolute);
    var minutes = Number((absolute - degrees) * 60).toFixed(3);

    return `${degrees}° ${minutes}’`;
  };

  const handleGPXFile = () => {
    setLoading(true);
    setSelected(undefined);
    setTrackSegments([]);
    setTrackSegment("");
    var reader = new FileReader();
    reader.readAsText(gpxFile, "UTF-8");
    reader.onload = function (evt) {
      var xmlDoc = new DOMParser().parseFromString(
        evt.target.result,
        "text/xml"
      );
      setXmlDoc(xmlDoc);
    };
    reader.onerror = function (_evt) {
      console.error("error reading file");
    };
  };

  React.useEffect(() => {
    if (xmlDoc) {
      parseData();
    }
    // eslint-disable-next-line
  }, [xmlDoc]);

  React.useEffect(() => {
    if (selected) {
      parseData();
    }

    // eslint-disable-next-line
  }, [metric, dms, offset]);

  React.useEffect(() => {
    if (selected && selected.data.length > 1) {
      calculateDuration();
    }

    // eslint-disable-next-line
  }, [selected]);

  const calculateDuration = () => {
    if (selected) {
      const { hours, minutes, seconds } = intervalToDuration({
        start: new Date(selected.data[0].time),
        end: new Date(selected.data[selected.data.length - 1].time),
      });
      let duration = "";
      if (hours) {
        duration += hours + " hour";
        duration += hours > 1 ? "s " : " ";
      }
      if (minutes) {
        duration += minutes + " minute";
        duration += minutes > 1 ? "s " : " ";
      }
      if (seconds) {
        duration += seconds + " second";
        duration += seconds > 1 ? "s" : "";
      }
      setDuration(duration.length > 0 ? duration : "None");
    }
  };

  const degreesToRadians = (degrees) => {
    var radians = (degrees * Math.PI) / 180;
    return radians;
  };

  const calcDistance = (startCoords, destCoords) => {
    let startingLat = degreesToRadians(startCoords.latitude);
    let startingLong = degreesToRadians(startCoords.longitude);
    let destinationLat = degreesToRadians(destCoords.latitude);
    let destinationLong = degreesToRadians(destCoords.longitude);

    // Radius of the Earth in kilometers
    let radius = 6371;

    // Haversine equation
    let distanceInKilometers =
      Math.acos(
        Math.sin(startingLat) * Math.sin(destinationLat) +
          Math.cos(startingLat) *
            Math.cos(destinationLat) *
            Math.cos(startingLong - destinationLong)
      ) * radius;

    return Number(distanceInKilometers * 1000).toFixed(1);
  };

  const parseData = () => {
    setLoading(true);
    parse();
    setLoading(false);
  };

  const getDepth = (point) => {
    let result = Number(0);
    if (point.getElementsByTagName("gpxtpx:depth").length > 0) {
      result = Number(
        point.getElementsByTagName("gpxtpx:depth")[0]?.textContent || 0
      ).toFixed(2);
    } else {
      if (point.getElementsByTagName("ele").length > 0) {
        result = Number(
          point.getElementsByTagName("ele")[0]?.textContent || 0
        ).toFixed(2);
      }
    }
    return (metric ? result : convertToFeet(result)) - Number(offset);
  };

  const parse = () => {
    let map = new Map();
    var tracks = xmlDoc.getElementsByTagName("trk");
    for (var j = 0; j < tracks.length; j++) {
      var track = tracks[j];
      var segments = track.getElementsByTagName("trkseg");
      // some aquamap files don't have trkseg elements, so use trk
      if (segments.length === 0) {
        segments = [track];
      }
      for (var k = 0; k < segments.length; k++) {
        var trackName =
          track.getElementsByTagName("name")[0].textContent +
          (segments.length > 1 ? ` segment ${k + 1}` : "");
        var points = segments[k].getElementsByTagName("trkpt");
        let d = [];
        let min = 10000;
        let max = 0;
        let lastCoordinates = undefined;

        for (var i = 0; i < points.length; i++) {
          let point = points[i];
          let latitude = Number(point.getAttribute("lat")).toFixed(8);
          let longitude = Number(point.getAttribute("lon")).toFixed(8);
          let distance = lastCoordinates
            ? calcDistance(lastCoordinates, {
                latitude: latitude,
                longitude: longitude,
              })
            : 0;
          lastCoordinates = { latitude: latitude, longitude: longitude };
          let depth = Math.abs(getDepth(point));
          min = Math.min(min, depth);
          max = Math.max(max, depth);

          let entry = {
            id: i,
            lat: dms ? convertDmsLatitude(latitude) : latitude,
            lon: dms ? convertDmsLongitude(longitude) : longitude,
            latDD: latitude,
            lonDD: longitude,
            depth: depth,
            time: Date.parse(
              point.getElementsByTagName("time")[0]?.textContent || 0
            ),
            dist: metric ? distance : convertToFeet(distance).toFixed(1),
          };
          d.push(entry);
        }

        map.set(trackName, { data: [...d], min: min, max: max });
        min = 0;
        max = 0;
        d.length = 0;
      }
    }
    setTrackSegments(Array.from(map.keys()));
    setSegmentMap(map);
    let segment = trackSegment === "" ? map.keys().next().value : trackSegment;
    setTrackSegment(segment);
    setFileName(segment.toLowerCase().replaceAll(" ", "_"));
    let entry = map.get(segment);
    setSelected({
      data: entry.data,
      min: entry.min,
      max: entry.max,
    });
    setScrollIndex(0);
    calculateDuration();
  };

  const downloadCsvFile = () => {
    var csv = `Timestamp,Latitude,Longitude,Depth (${
      metric ? "m" : "ft"
    }),Speed,Distance (${metric ? "m" : "ft"})\n`;
    selected.data.forEach(function (row) {
      csv += `${new Date(row.time).toISOString()},${
        dms ? row.lat : Number(row.lat).toFixed(7)
      },${dms ? row.lon : Number(row.lon).toFixed(7)},${Math.abs(
        Number(row.depth)
      ).toFixed(1)},${Number(row.speed).toFixed(1)},${Number(row.dist).toFixed(
        1
      )}`;
      csv += "\n";
    });

    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    element.setAttribute(
      "download",
      gpxFile.name.substring(0, gpxFile.name.indexOf(".")) + ".csv"
    );
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const saveTrack = () => {
    var xml = ['<?xml version="1.0" encoding="UTF-8"?>'];
    xml.push(
      `<gpx xmlns="http://www.topografix.com/GPX/1/1" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" version="1.1" xsi:schemaLocation="http://www.topografix.com/GPX/1/1 http://www.topografix.com/GPX/1/1/gpx.xsd"><trk><cmt>Saved from original file ${gpxFile.name}</cmt><name>${trackSegment}</name><desc>${trackSegment}</desc><trkseg>`
    );
    selected.data.forEach(function (row) {
      xml.push(
        `<trkpt lat="${Number(row.latDD).toFixed(7)}" lon="${Number(
          row.lonDD
        ).toFixed(7)}"><ele>${Math.abs(
          metric ? Number(row.depth) : convertToMeters(Number(row.depth))
        ).toFixed(1)}</ele><time>${new Date(
          row.time
        ).toISOString()}</time></trkpt>`
      );
    });
    xml.push("</trkseg></trk></gpx>");

    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/xml;charset=utf-8," + encodeURIComponent(xml.join(""))
    );
    element.setAttribute("download", fileName + ".gpx");
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const totalDistance = () => {
    let distance = Number(
      selected.data
        .map((d) => d.dist)
        .reduce(
          (a, b) => Number(isNaN(a) ? 0 : a) + Number(isNaN(b) ? 0 : b),
          0
        )
    );
    return metric ? (distance / 1000).toFixed(2) : (distance / 5280).toFixed(2);
  };

  const getFileName = () => {
    if (gpxFile) {
      return gpxFile.name;
    } else {
      return file.substring(
        file.indexOf("<name>") + 6,
        file.indexOf("</name>")
      );
    }
  };

  return (
    <div>
      <Typography variant="h4" mb={5}>
        GPX Viewer
      </Typography>
      <Typography>
        To view the data in a GPX file, select it below. <br />
        <br />
        Files types currently supported are{" "}
        <Link
          underline="hover"
          href="https://www.aquamap.app/"
          target="_blank"
          rel="noreferrer"
        >
          AquaMap
        </Link>{" "}
        track files and{" "}
        <Link
          underline="hover"
          href="https://support.garmin.com/en-US/?faq=maUIjVHE8EAxWWcLdWD508"
          target="_blank"
          rel="noreferrer"
        >
          exported
        </Link>{" "}
        Garmin GPSMAP chartplotter user data files.
        <br /> <br /> If you have a CSB (Crowdsourced Bathymetry) file and want
        to convert it to GPX format, click{" "}
        <Link underline="hover" href="/csb2gpx" rel="noreferrer">
          here
        </Link>
        .
      </Typography>
      <br />
      <div style={{ width: "100%" }}>
        <Grid container mb={4} mt={1}>
          <Grid item="true" md={4} xs={12}>
            <label htmlFor="open-gpx">
              <Button
                variant="outlined"
                component="span"
                size="large"
                disabled={loading}
              >
                {loading ? "Loading..." : "Select GPX File"}
              </Button>
              <input
                id="open-gpx"
                hidden
                accept=".gpx"
                type="file"
                onChange={(event) => {
                  if (event.target.files?.length > 0) {
                    setGpxFile(event.target.files[0]);
                  }
                }}
              />
            </label>
          </Grid>
          <Grid item="true" md={8} xs={12}></Grid>
        </Grid>
        {selected && <Typography mt={1}>File: {getFileName()}</Typography>}
        {trackSegments.length > 0 && (
          <Stack direction="row" spacing={1} alignItems="center" mt={4} mb={2}>
            <FormControl fullWidth sx={{ marginTop: 2, marginBottom: 2 }}>
              <InputLabel id="trackSegment-select-label">
                Track Segment
              </InputLabel>
              <Select
                labelId="trackSegment-select-label"
                id="trackSegment-simple-select"
                value={trackSegment}
                label="Track Segment"
                size="small"
                onChange={(event) => {
                  setTrackSegment(event.target.value);
                  setSelected(segmentMap.get(event.target.value));
                  setScrollIndex(0);
                  setFileName(
                    event.target.value.toLowerCase().replaceAll(" ", "_")
                  );
                }}
              >
                {trackSegments.map((d) => (
                  <MenuItem value={d} key={d}>
                    {d}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selected && (
              <Tooltip title="Download CSV file of data">
                <IconButton
                  aria-label="download"
                  onClick={() => downloadCsvFile()}
                >
                  <TableChartIcon />
                </IconButton>
              </Tooltip>
            )}
            {selected && (
              <Tooltip title="Save track to new file">
                <IconButton onClick={() => setSaveFile(true)}>
                  <SaveAltIcon />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
        )}
        {selected && (
          <Typography mt={1}>
            Points: {selected.data.length.toLocaleString()}
          </Typography>
        )}
        {selected && (
          <Typography mt={1}>
            Distance: {totalDistance()} {metric ? "km" : "mi"}
          </Typography>
        )}
        {selected && (
          <Typography mt={1}>
            Min Depth: {selected.min.toFixed(2)} {metric ? "(m)" : "(ft)"}
          </Typography>
        )}
        {selected && (
          <Typography mt={1}>
            Max Depth: {selected.max.toFixed(2)} {metric ? "(m)" : "(ft)"}
          </Typography>
        )}
        {selected && (
          <Typography mt={1}>
            Start:{" "}
            {selected.data.length > 0
              ? new Date(selected.data[0].time).toLocaleDateString()
              : ""}{" "}
            {selected.data.length > 0
              ? new Date(selected.data[0].time).toLocaleTimeString()
              : ""}
          </Typography>
        )}
        {selected && (
          <Typography mt={1}>
            End:{" "}
            {selected.data.length > 0
              ? new Date(
                  selected.data[selected.data.length - 1].time
                ).toLocaleDateString()
              : ""}{" "}
            {selected.data.length > 0
              ? new Date(
                  selected.data[selected.data.length - 1].time
                ).toLocaleTimeString()
              : ""}
          </Typography>
        )}
        {selected && (
          <Typography mt={1} mb={3}>
            Duration: {duration}
          </Typography>
        )}
        {selected && (
          <Stack
            direction="row"
            spacing={2}
            alignItems="baseline"
            sx={{ mb: 2 }}
          >
            <TextField
              sx={{ width: 100 }}
              id="offset-number"
              size="small"
              label="Depth Offset"
              value={offset}
              onChange={(e) => {
                setOffset(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {metric ? "m" : "ft"}
                  </InputAdornment>
                ),
              }}
            />
            <Typography mb={4}>
              Adjust the depth by a positive or negative offset value
            </Typography>
          </Stack>
        )}
        <Grid
          mb={0.3}
          container
          columns={{ md: 3, sm: 1 }}
          spacing={{ sm: 1, md: 5 }}
          alignItems="baseline"
          justifyContent="flex-start"
        >
          {selected && (
            <Grid item="true" xs="auto">
              <Typography>Display Units:</Typography>
            </Grid>
          )}
          {selected && (
            <Grid item="true" xs="auto">
              <Stack direction="row" alignItems="center">
                <Typography sx={{ marginRight: "24px" }}>DD</Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={dms}
                        onChange={(event) => {
                          setDms(event.target.checked);
                          setSelectedPoints([]);
                        }}
                      />
                    }
                  />
                </FormGroup>
                <Typography>DM.M</Typography>
              </Stack>
            </Grid>
          )}
          {selected && (
            <Grid item="true" xs="auto">
              <Stack direction="row" alignItems="center">
                <Typography sx={{ marginRight: "24px" }}>Meters</Typography>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={!metric}
                        onChange={(event) => {
                          setMetric(!event.target.checked);
                          setSelectedPoints([]);
                        }}
                      />
                    }
                  />
                </FormGroup>
                <Typography>Feet</Typography>
              </Stack>
            </Grid>
          )}
        </Grid>
        {loading && (
          <Box sx={{ display: "flex" }} m={10}>
            <CircularProgress />
          </Box>
        )}
        {selected && (
          <TrackTable
            data={selected.data}
            scrollIndex={scrollIndex}
            metric={metric}
            setMetric={setMetric}
            selectedPoints={selectedPoints}
            setSelectedPoints={setSelectedPoints}
            deletePoints={deletePoints}
          />
        )}
        {selected && (
          <Grid item="true" xs="auto" mt={4}>
            <Stack direction="row" alignItems="center">
              <Typography sx={{ marginRight: "24px" }}>
                Track mouse pointer
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={trackPointer}
                      onChange={(event) => {
                        setTrackPointer(event.target.checked);
                        setSelectedPoints([]);
                      }}
                    />
                  }
                />
              </FormGroup>
            </Stack>
          </Grid>
        )}
        {selected && (
          <ZoomableLineChart
            map={selected}
            metric={metric}
            setScrollIndex={setScrollIndex}
            selected={selectedPoints}
            trackPointer={trackPointer}
          />
        )}
        {selected && (
          <Box sx={{ height: 500, width: "100%" }}>
            <MapView
              data={selected.data}
              scrollIndex={scrollIndex}
              setScrollIndex={setScrollIndex}
              metric={metric}
              pointThreshold={pointThreshold}
              dotSize={dotSize}
            />
          </Box>
        )}
        {selected && (
          <Stack
            direction="row"
            spacing={2}
            alignItems="baseline"
            sx={{ mt: 4 }}
          >
            <TextField
              sx={{ width: 100 }}
              id="dot-size-number"
              size="small"
              label="Dot Size"
              value={dotSize}
              onChange={(e) => {
                setDotSize(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Typography mb={4}>Size of dots on map</Typography>
          </Stack>
        )}
        {selected && (
          <Stack
            direction="row"
            spacing={2}
            alignItems="baseline"
            sx={{ mt: 2 }}
          >
            <TextField
              sx={{ width: 100 }}
              id="threshold-number"
              size="small"
              label="Depth Alarm"
              value={pointThreshold}
              onChange={(e) => {
                setPointThreshold(e.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {metric ? "m" : "ft"}
                  </InputAdornment>
                ),
              }}
            />
            <Typography mb={4}>Depth below which to show red points</Typography>
          </Stack>
        )}
      </div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        action={action}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          File successfully processed!
        </Alert>
      </Snackbar>
      {saveFile && (
        <Dialog open={saveFile} onClose={() => setSaveFile(false)} width={400}>
          <DialogTitle>Save Track</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the name the file to save this track to your Downloads
              folder.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="File name"
              type="text"
              fullWidth
              variant="standard"
              value={fileName}
              onChange={(event) => setFileName(event.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setSaveFile(false)}>Cancel</Button>
            <Button
              onClick={() => {
                saveTrack();
                setSaveFile(false);
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
