import * as React from "react";
import { GoogleMap, Marker, AdvancedMarker, Pin } from "@react-google-maps/api";
import "./Map.css";
import useMediaQuery from "@mui/material/useMediaQuery";
import { lightTheme } from "./lightTheme";
import { darkTheme } from "./darkTheme";

export default function MapView(props) {
  const { data, setScrollIndex, metric, scrollIndex, pointThreshold, dotSize } =
    props;
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [map, setMap] = React.useState(null);
  const [crosshairPosition, setCrosshairPosition] = React.useState({
    lat: data.length > 0 ? Number(data[0].latDD) : 0,
    lng: data.length > 0 ? Number(data[0].lonDD) : 0,
  });

  const onLoad = React.useCallback((map) => setMap(map), []);

  React.useEffect(() => {
    if (map) {
      const bounds = new window.google.maps.LatLngBounds();
      data?.forEach(({ latDD, lonDD }) =>
        bounds.extend({ lat: Number(latDD), lng: Number(lonDD) })
      );
      map.fitBounds(bounds);
    }
  }, [map, data]);

  React.useEffect(() => {
    if (data && map && data[scrollIndex]) {
      let coords = {
        lat: Number(data[scrollIndex].latDD),
        lng: Number(data[scrollIndex].lonDD),
      };
      map.panTo(coords);
      setCrosshairPosition(coords);
    }
    // eslint-disable-next-line
  }, [data, scrollIndex]);

  const getDot = (depth) => {
    let adjusted = metric === false ? depth : depth / 3.28084;

    return Math.abs(adjusted) > pointThreshold
      ? "https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png"
      : "https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle.png";
  };

  var image = {
    url: "https://maps.gstatic.com/mapfiles/ms2/micons/red.png",
    scaledSize: new window.google.maps.Size(24, 24),
    // anchor: new window.google.maps.Point(12, 12),
  };

  return (
    <GoogleMap
      onLoad={onLoad}
      mapContainerClassName="map-container"
      options={{
        mapTypeId: "terrain",
        scrollwheel: false,
        zoomControl: true,
        streetViewControl: false,
        styles: prefersDarkMode ? darkTheme : lightTheme,
        mapTypeControlOptions: {
          style: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        },
        zoomControlOptions: {
          position: window.google.maps.ControlPosition.LEFT_BOTTOM,
        },
      }}
    >
      {data.length > 0 && crosshairPosition && data[scrollIndex] && (
        <Marker position={crosshairPosition} icon={image}></Marker>
      )}
      {data.length > 0 &&
        data.map(({ latDD, lonDD, depth }, index) => (
          <Marker
            position={{ lat: Number(latDD), lng: Number(lonDD) }}
            key={index}
            onClick={() => {
              setScrollIndex(index);
            }}
            // onMouseOver={() => setScrollIndex(index)}
            icon={{
              url: getDot(depth),
              size: new window.google.maps.Size(dotSize || 1, dotSize || 1),
            }}
          />
        ))}
    </GoogleMap>
  );
}
