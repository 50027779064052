import React from "react";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { useUploadFileMutation, useConvertUrlQuery } from "../../store/api";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import { setFile } from "../../store/app";
import { useNavigate } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/dist/query";

export default function Converter() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const file = useSelector((state) => state.app.file);
  const [csbFile, setCsbFile] = React.useState(undefined);
  const [url, setUrl] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  const [submitUrl, setSubmitUrl] = React.useState(undefined);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = (_event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const [upload] = useUploadFileMutation();
  const { data } = useConvertUrlQuery(submitUrl ? url : skipToken);

  const handleFile = (event) => {
    setCsbFile(event.target.files[0]);
  };

  const downloadFile = (filename, text) => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/xml;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true);
    if (csbFile) {
      const formData = new FormData();
      formData.append("file", csbFile);
      formData.append("fileName", csbFile.name);
      upload(formData).then((response) => {
        complete(response.data);
      });
    } else {
      setSubmitUrl(true);
    }
  };

  React.useEffect(() => {
    if (data) {
      complete(data);
    }
    // eslint-disable-next-line
  }, [data]);

  const complete = (transformed) => {
    let name = transformed.substring(
      transformed.indexOf("<name>") + 6,
      transformed.indexOf("</name>")
    );
    downloadFile(name, transformed);
    dispatch(setFile(transformed));
  };

  React.useEffect(() => {
    if (file) {
      setUrl(undefined);
      setCsbFile(undefined);
      setOpen(false);
      if (checked) {
        navigate("/");
      }
    }
    // eslint-disable-next-line
  }, [file]);

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div>
      <Typography variant="h4" mb={5}>
        CSB2GPX Converter
      </Typography>
      <Typography>
        This conversion utility is for those interested in creating *.gpx files
        suitable for import to{" "}
        <Link
          underline="hover"
          href="https://www.aquamap.app"
          target="_blank"
          rel="noreferrer"
        >
          AquaMap
        </Link>{" "}
        from NOAA{" "}
        <Link
          underline="hover"
          href="https://www.ncei.noaa.gov/maps/iho_dcdb/"
          target="_blank"
          rel="noreferrer"
        >
          CSB (Crowdsourced Bathymetry)
        </Link>{" "}
        files.{" "}
      </Typography>
      <br />
      <Typography>
        You can find instructions on how to download CSB files from Bob423's{" "}
        <Link underline="hover" href="https://bobicw.blogspot.com">
          ICW Tracks and Routes
        </Link>{" "}
        website page{" "}
        <Link
          underline="hover"
          href="https://bobicw.blogspot.com/p/crowd-sourced-bathymetry.html"
          target="_blank"
          rel="noreferrer"
        >
          here
        </Link>{" "}
        (note you will need to join the group before the link will take you to
        the post).
      </Typography>
      <br />
      <Typography>
        When you receive your NCEI Data Extraction email, copy the link it
        contains and paste below (it should look similar to
        https://www.ngdc.noaa.gov/next-web/rest/orders/506346/pickup).
      </Typography>

      {!csbFile && (
        <FormControl sx={{ mt: 2, mr: 4 }} variant="outlined" fullWidth>
          <InputLabel htmlFor="url">Paste URL here</InputLabel>
          <OutlinedInput
            id="url"
            type={"text"}
            onChange={(event) => {
              setUrl(event.target.value);
            }}
            size="medium"
            label="Paste URL here"
            value={url}
          />
        </FormControl>
      )}
      <br />
      <br />
      <Stack direction="row" alignItems="center" spacing={2} mb={5}>
        <form onSubmit={handleSubmit}>
          {!csbFile && !url && (
            <div>
              <Typography>If you have already downloaded the file:</Typography>
              <br />
              <label htmlFor="upload-file">
                <Button variant="outlined" component="span" size="large">
                  Select File
                </Button>
                <input
                  id="upload-file"
                  hidden
                  accept="application/gzip"
                  type="file"
                  onChange={handleFile}
                />
              </label>
            </div>
          )}
          {csbFile && <Typography>File: {csbFile.name}</Typography>}
          {(url || csbFile) && (
            <Stack direction="row" alignItems="center" spacing={2} mt={5}>
              <Button variant="outlined" type={"submit"} size="large">
                Submit
              </Button>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    checked={checked}
                    onChange={handleChange}
                  />
                }
                label="Automatically open GPX file in viewer"
              />
            </Stack>
          )}
        </form>
      </Stack>
      <br />
      <Typography>
        If you already have the GPX file, click{" "}
        <Link underline="hover" href="/" rel="noreferrer">
          here
        </Link>{" "}
        to view file data.
      </Typography>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        action={action}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Processing file
        </Alert>
      </Snackbar>
    </div>
  );
}
