import React, { useRef, useEffect, useState } from "react";
import {
  select,
  scaleLinear,
  scaleTime,
  area,
  extent,
  bisector,
  pointer,
  axisTop,
  axisLeft,
  zoom,
  curveMonotoneX,
} from "d3";
import useResizeObserver from "./useResizeObserver";
import "./Chart.css";

function ZoomableLineChart({
  map,
  metric,
  setScrollIndex,
  selected,
  trackPointer,
  id = "myZoomableLineChart",
}) {
  const svgRef = useRef();
  const wrapperRef = useRef();
  const dimensions = useResizeObserver(wrapperRef);
  const { data, max, min } = map;
  const [currentZoomState, setCurrentZoomState] = useState();

  if (selected?.length > 0) {
    select(svgRef.current).select("#focus").style("display", "none");
  }

  // will be called initially and on every data change
  useEffect(() => {
    const svg = select(svgRef.current).on(
      "pointerenter pointermove mousedown",
      (event) => {
        event.preventDefault();
        pointermoved(event);
      }
    );

    const svgContent = svg.select(".content");
    const { width, height } =
      dimensions || wrapperRef.current.getBoundingClientRect();

    var xDomain = extent(data, function (d) {
      return d.time;
    });

    var yDomain = extent(data, function (d) {
      return d.depth;
    });

    // scales + line generator
    const xScale = scaleTime(xDomain, [10, width - 10]);

    const yScale = scaleLinear()
      .domain([max, 0])
      .range([height - 10, 10]);

    if (currentZoomState) {
      const newXScale = currentZoomState.rescaleX(xScale);
      xScale.domain(newXScale.domain());
    }

    const areaGenerator = area()
      .x((d) => xScale(d.time))
      .y0(yScale(0))
      .y1((d) => yScale(Math.abs(Number(d.depth))))
      .curve(curveMonotoneX);

    // render the area
    svgContent
      .selectAll(".myLine")
      .data([data])
      .join("path")
      .attr("class", "myLine")
      .attr("stroke", "steelblue")
      .attr("fill", "steelblue")
      .attr("opacity", 0.5)
      .attr("d", areaGenerator);

    svgContent
      .selectAll(".myDot")
      .data(data)
      .join("circle")
      .attr("class", "myDot")
      .attr("stroke", "steelblue")
      .attr("r", 0.5)
      .attr("fill", "steelblue")
      .attr("cx", (value) => xScale(value.time))
      .attr("cy", (value) => yScale(Math.abs(Number(value.depth))));

    // axes
    const xAxis = axisTop(xScale);
    svg.select(".x-axis").attr("transform", `translate(-8, 8)`).call(xAxis);

    const yAxis = axisLeft(yScale);
    svg.select(".y-axis").call(yAxis);

    // focus tracking
    svg
      .node()
      .querySelectorAll("#focus")
      .forEach((e) => e.remove());
    var focus = svg.append("g").attr("id", "focus").style("display", "none");
    const bisect = bisector((d) => d.time).center;
    focus.append("line").attr("id", "focusLineX").attr("class", "focusLine");
    focus.append("line").attr("id", "focusLineY").attr("class", "focusLine");
    focus
      .append("circle")
      .attr("id", "focusCircle")
      .attr("r", 2.5)
      .attr("class", "circle focusCircle");

    function pointermoved(event) {
      if (
        (event.type === "mousedown" && !trackPointer) ||
        (trackPointer &&
          (event.type === "pointerenter" || event.type === "pointermove"))
      ) {
        const i = bisect(data, xScale.invert(pointer(event)[0]));
        setScrollIndex(i);

        var d = data[i];
        var x = xScale(d.time);
        var y = yScale(Math.abs(Number(d.depth)));

        focus.style("display", "block");
        focus.select("#focusCircle").attr("cx", x).attr("cy", y);
        focus
          .select("#focusLineX")
          .attr("x1", x)
          .attr("y1", 10)
          .attr("x2", x)
          .attr("y2", yScale(yDomain[1]));
        focus
          .select("#focusLineY")
          .attr("x1", xScale(xDomain[0]))
          .attr("y1", y)
          .attr("x2", xScale(xDomain[1]))
          .attr("y2", y);
      }
    }

    // zoom
    const zoomBehavior = zoom()
      .scaleExtent([0.75, 100])
      .translateExtent([
        [0, 0],
        [width, height],
      ])
      .on("zoom", (event) => {
        const zoomState = event.transform;
        setCurrentZoomState(zoomState);
        focus.style("display", "none");
      });

    svg.call(zoomBehavior);

    // eslint-disable-next-line
  }, [currentZoomState, data, dimensions, metric, max, min, trackPointer]);

  return (
    <React.Fragment>
      <div ref={wrapperRef} style={{ marginBottom: "2rem", marginTop: "2rem" }}>
        <svg
          ref={svgRef}
          style={{
            display: "block",
            overflow: "visible",
            width: "100%",
            height: "400px",
          }}
        >
          <defs>
            <clipPath id={id}>
              <rect x="0" y="0" width="100%" height="100%" />
            </clipPath>
          </defs>
          <g className="content" clipPath={`url(#${id})`}></g>
          <g className="x-axis" />
          <g className="y-axis" />
        </svg>
      </div>
    </React.Fragment>
  );
}

export default ZoomableLineChart;
