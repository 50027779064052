import * as React from "react";
import { createRoot } from "react-dom/client";
import App from "./app/App";
import { store } from "./store/index";
import { Provider } from "react-redux";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(
  "4520cb38c615b4e38de7cece2d5ec89aTz03ODc5NixFPTE3MzE2ODc0NjcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

const container = document.querySelector("#root");
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
